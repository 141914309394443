import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import AnimationRevealPage from '../../components/AnimationRevealPage';
import Sports from '../../components/Sports';

export default function PoleSportif() {
  return (
    <AnimationRevealPage>
      <Layout>
        <Seo
          title="Collègé Jules Verne - Pôle Sportif"
          description="N'hésitez pas à nous contacter pour toutes questions ou demande"
        />
        <Sports />
      </Layout>
    </AnimationRevealPage>
  );
}
