import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function Sports() {
  return (
    <div className="p-5 mx-auto sm:p-10 md:p-16 bg-coolGray-100 text-gray-800">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-center">
        <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto text-center">
            <span class="relative inline-block text-blue-900">Activités périscolaires</span>
          </h2>
          {/* <p class="text-base text-gray-700 md:text-lg">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            rem aperiam, eaque ipsa quae.
          </p> */}
          <div class="flex mt-6 justify-center">
            <div class="w-16 h-1 rounded-full bg-blue-900 inline-flex"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col max-w-3xl mx-auto overflow-hidden rounded">
        <StaticImage
          src="../images/sports.webp"
          alt=""
          className="w-full h-60 sm:h-96 bg-coolGray-500"
        />

        <div className="p-6 pb-12 m-4 mx-auto -mt-16 space-y-6 lg:max-w-2xl sm:px-10 sm:mx-12 lg:rounded-md bg-white shadow-lg z-10">
          {/* <div className="space-y-2">
                <a
                  rel="noopener noreferrer"
                  href="#"
                  className="inline-block text-2xl font-semibold sm:text-3xl"
                >
                  The Best Activewear from the Nordstrom Anniversary Sale
                </a>
                <p className="text-xs text-coolGray-600">
                  By
                  <a rel="noopener noreferrer" href="#" className="text-xs hover:underline">
                    Leroy Jenkins
                  </a>
                </p>
              </div> */}
          <div className="text-coolGray-800">
            <p>
              En complément de son offre éducative, le collège français de Kairouan offre un
              programme d’activités périscolaires à destination de ses élèves . Cette offre consiste
              en :
            </p>
            <br />
            <ul className="list-disc space-y-2">
              <li>
                Des études dirigées encadrées par des enseignants: Les élèves y font leurs devoirs
                et peuvent approfondir toute notion abordée en classe à leur souhait et besoins
                constatés par les équipes pédagogiques . Les effectifs réduits permettront un suivi
                et un accompagnement individuel des élèves par les enseignants et les autres
                intervenants.
              </li>
              <li>
                Des ateliers culturels et sportifs animés par des intervenants spécialisés sont
                programmés de manière régulière pour enrichir les parcours artistiques et leurs
                cultures générales.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
